@media (max-width: 500px) {
    .card-columns {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }
  }
  
  @media (min-width: 500px) {
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3 !important;
    }
  }

  @media print {
    .no-printme {
      display: none !important;
    }

    .printme {
      display: block !important;
    }
    @page {
      size: 1600px !important;
    }
  
    body,
    html {
      width: 1600px;
    }
  }
  