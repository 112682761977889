.pricing-title {
  font-family: 'Lemonmilk', Arial, sans-serif;
  text-align: center;
  margin-bottom: 30px;
  color: #074a5a;
}

.pricing-card {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border: none !important;
}

.pricing-card-header {
  background: linear-gradient(to right, #074a5a, #0a6285);
  padding: 20px 25px;
  cursor: pointer;
  border-bottom: none;
  font-weight: bold;
  font-family: 'Lemonmilk', Arial, sans-serif;
  color: white !important;
  font-size: 18px;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  height: 100px;
}

.pricing-card-header::after {
  content: "+";
  position: absolute;
  right: 25px;
  font-size: 24px;
  font-weight: normal;
  transition: transform 0.3s ease;
}

.pricing-card-header[aria-expanded="true"]::after {
  transform: rotate(45deg);
}

.pricing-card-header:hover {
  background: linear-gradient(to right, #0a6285, #074a5a);
  box-shadow: 0 5px 15px rgba(7, 74, 90, 0.2);
}

.pricing-section-title {
  font-family: 'Lemonmilk', Arial, sans-serif;
  font-size: 16px;
  color: #074a5a;
  margin-bottom: 15px;
}

.pricing-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.pricing-item {
  flex: 1;
  font-weight: 500;
}

.pricing-dots {
  flex: 2;
  margin: 0 15px;
}

.pricing-price {
  text-align: right;
}

.pricing-current {
  font-weight: bold;
  color: #074a5a;
  font-size: 1.1em;
}

.pricing-original {
  text-decoration: line-through;
  color: #6c757d;
  margin-left: 10px;
  font-size: 0.9em;
}

.pricing-note {
  font-style: italic;
  margin: 15px 0;
  color: #6c757d;
}