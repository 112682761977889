.selected-package {
    background-color: rgba(7, 74, 90, 0.05);
}

.white-bg {
    background-color: white;
}

.package-card {
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    border: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.package-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.service-item {
    display: flex;
    margin-bottom: 10px;
    align-items: flex-start;
}

.service-bullet {
    margin-right: 8px;
    color: #ff871e;
    font-weight: bold;
}

.service-text {
    flex: 1;
}

.price-container {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.selected-button {
    background-color: #074a5a !important;
    color: white !important;
}