.App {
  text-align: center;
}

div {
  font-family: "Avenir Next", "Arial", "sans-serif" !important;
  white-space: pre-line !important;
}

html, body, #root {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100%;
  min-height: 100vh;
}

p {
  font-family: "Avenir Next", "Arial", "sans-serif" !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 576px) {
  body, html {
    max-width: 100%;
  }
}

@media print {
  .no-printme {
    display: none;
  }
  .printme {
    display: block;
  }
  @page {
    size: 1600px;
  }

  body,
  html {
    width: 1600px;
  }
}

/* Fix for footer spacing issues */
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-footer {
  margin-top: auto !important;
}
