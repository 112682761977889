
@font-face {
    font-family: "Lemonmilk";
    src: url("../fonts/lemon_milk/LEMONMILK-Bold.otf") format("truetype");
}

.text-overlay h1 {
    font-size: 3.5em; /* Adjust the font size as needed */    
    font-family: 'Lemonmilk', Arial, sans-serif;
    font-weight: bold;
    text-align: left;
    align-items: flex-start;
    width: 500px;
}

.text-overlay p {
    font-size: 1.5em; /* Adjust the font size as needed */
    margin: 0;
}

.pill-button {
    background-color: #ff871e !important;
    color: white !important;
    border-radius: 50px !important;
    padding: 10px 20px !important;
    font-size: 1.2em !important;
    text-align: center !important;
    text-decoration: none !important;
    display: inline-block !important;
    margin-top: 20px !important;
    border: none !important;
}

.left-align-button {
    display: block;
    width: 370px;
}

.pill-button:hover {
    background-color: #074a5a !important;
    color: white !important;
}

@media (max-width: 768px) {
    .pill-button {
        font-size: 1em !important;
        padding: 8px 16px !important;
        width: 100%;
    }

    .text-overlay h1 {
        font-size: 2.5em !important;
        width: 100%;
    }
}

.image-text-overlay {
    position: relative;
}

.image-text-overlay .text-overlay {
    position: absolute;
    top: 0;
    left: 0;
    color: white;
    text-align: left;
    width: 100%;
    height: 100%;
    padding: 20px;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.4);     
}

.text-wrapper {    
    margin: 40px 0 0 40px;
}

.header-links {
    margin-left: 40px;
    margin-top: 20px;    
}

.header-links a {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 30px;
    background-color: white;
    color: #074a5a;
    font-family: 'Lemonmilk', Arial, sans-serif;
    text-decoration: none;
    display: inline-block;
    margin: 0 5px;
}

@media (max-width: 768px) {
    .header-links a {
        display: block;
        margin: 5px 0;
    }
}

.section-title {
    font-family: 'Lemonmilk', Arial, sans-serif;
    text-align: center;
    margin-bottom: 40px;
    color: #074a5a;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Lemonmilk', Arial, sans-serif;
    color: #074a5a;
}

.content-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
}

.section-separator {
    margin-top: 80px;
    margin-bottom: 80px;
}

.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto;
    max-width: 600px;
}

.centered-video {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    display: block;
}

.text-wrap-section {
    position: relative;
}

.floating-image-right {
    float: right;
    margin: 0 0 20px 30px;
    width: 40%;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .floating-image-right {
        float: none;
        width: 100%;
        margin: 0 0 30px 0;
        display: block;
    }
}

.modern-cta {
    background-color: #ff871e;
    border-radius: 16px;
    box-shadow: 0 10px 30px rgba(255, 135, 30, 0.2);
    padding: 40px;
    text-align: center;
    margin: 100px auto;
    max-width: 800px;
    color: white;
}

.modern-cta-title {
    font-family: 'Lemonmilk', Arial, sans-serif;
    color: white;
    font-size: 28px;
    margin-bottom: 20px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.modern-cta-discount {
    font-family: 'Lemonmilk', Arial, sans-serif;
    font-size: 56px;
    color: white;
    margin: 30px 0;
    font-weight: bold;
    text-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.modern-cta-button-selected {
    background-color: #074a5a !important;
    color: white !important;
    border: none !important;
    border-radius: 50px !important;
    padding: 15px 30px !important;
    font-size: 18px !important;
    font-weight: bold !important;
    margin: 15px 10px !important;
    transition: all 0.3s ease !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15) !important;
}

.modern-cta-button {
    background-color: white !important;
    color: #074a5a !important;
    border: none !important;
    border-radius: 50px !important;
    padding: 15px 30px !important;
    font-size: 18px !important;
    font-weight: bold !important;
    margin: 15px 10px !important;
    transition: all 0.3s ease !important;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15) !important;
}

.modern-cta-button:hover {
    background-color: #074a5a !important;
    color: white !important;
    transform: translateY(-3px) !important;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2) !important;
}

.modern-cta-secondary {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #f1f1f1;
}

.modern-cta-deadline {
    font-weight: bold;
    color: #074a5a;
}

.modern-cta-or {
    display: inline-block;
    position: relative;
    font-size: 18px;
    color: #6c757d;
    margin: 20px 0;
    width: 100%;
    text-align: center;
}

.modern-cta-or:before,
.modern-cta-or:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 42%;
    background-color: #f1f1f1;
    top: 50%;
}

.modern-cta-or:before {
    left: 0;
}

.modern-cta-or:after {
    right: 0;
}

.gallery-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    padding: 0 20px;
}

.gallery-card {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
}

.gallery-card img {
    width: 100%;
    height: 220px;
    object-fit: cover;
}

.gallery-card-body {
    padding: 18px;
    background-color: white;
}

.service-list {
    padding-left: 20px;
}

.service-list li {
    margin-bottom: 8px;
    position: relative;
    list-style-type: none;
    padding-left: 28px;
}

.service-list li:before {
    content: '\f00c';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    left: 0;
    top: 0;
    color: #074a5a;
}

.service-list li:last-child {
    margin-bottom: 0;
}

/* Custom Pricing Table Styles */
.pricing-table-container {
    background-color: white;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    border: 1px solid #e0e0e0;
}

.pricing-table {
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
}

.pricing-header {
    display: flex;
    background-color: #074a5a;
    color: white;
    font-weight: bold;
}

.pricing-row {
    display: flex;
    border-bottom: 1px solid #e5e5e5;
}

.pricing-row:last-child {
    border-bottom: none;
}

.pricing-row:nth-child(even) {
    background-color: #f9f9f9;
}

.pricing-cell {
    padding: 12px 16px;
    flex: 1;
    display: flex;
    align-items: center;
}

.pricing-cell.price {
    font-weight: bold;
    color: #074a5a;
    justify-content: center;
    border-left: 1px solid #e5e5e5;
}

@media (max-width: 576px) {
    .pricing-cell {
        padding: 10px;
        font-size: 14px;
    }
}

.numbered-list-container {
    background-color: white;
    border-radius: 16px;
    padding: 30px;
    color: #333;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    margin: 30px 0;
    border: 2px solid #074a5a;
}

.numbered-list {
    list-style-type: none;
    padding: 0;
    counter-reset: item;
}

.numbered-list li {
    margin-bottom: 15px;
    padding-left: 50px;
    position: relative;
    font-size: 1.1em;
}

.numbered-list li::before {
    counter-increment: item;
    content: counter(item);
    position: absolute;
    left: 0;
    top: 0;
    background-color: #074a5a;
    color: white;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: 'Lemonmilk', Arial, sans-serif;
}

.icon-card {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.icon-wrapper {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #074a5a;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    flex-shrink: 0;
    box-shadow: 0 3px 10px rgba(7, 74, 90, 0.2);
}

.icon-content {
    flex: 1;
}

.icon-content h3 {
    font-family: 'Lemonmilk', Arial, sans-serif;
    margin: 0 0 5px 0;
    font-size: 18px;
}

.icon-content p {
    margin: 0;
    color: #555;
}

.elegant-icon {
    margin-right: 10px;
    color: #074a5a;
}

.elegant-icon-white {
    margin-right: 10px;
    color: white;
}

.section-heading-with-icon {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.section-heading-with-icon .icon-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #074a5a;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    flex-shrink: 0;
    box-shadow: 0 3px 10px rgba(7, 74, 90, 0.2);
}

.section-heading-with-icon h2 {
    margin: 0;
}

.site-footer {
    background-color: #074a5a;
    color: white;
    padding: 60px 0 0;
    margin: 0;
}

.footer-column {
    margin-bottom: 30px;
}

.footer-column h4 {
    color: white;
    font-family: 'Lemonmilk', Arial, sans-serif;
    font-size: 18px;
    margin-bottom: 20px;
    position: relative;
    padding-bottom: 10px;
}

.footer-column h4::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 50px;
    background-color: #ff871e;
}

.footer-column ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer-column ul li {
    padding: 5px 0;
}

.footer-column ul li a {
    color: #e0e0e0;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-column ul li a:hover {
    color: #ff871e;
    text-decoration: none;
}

.footer-contact-info {
    margin-bottom: 20px;
}

.footer-contact-info p {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.footer-contact-info i {
    margin-right: 10px;
    color: #ff871e;
}

.footer-bottom {
    text-align: center;
    padding-top: 30px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 20px;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 14px;
}

.footer-social {
    margin-top: 20px;
}

.footer-social a {
    display: inline-block;
    margin-right: 15px;
    color: white;
    font-size: 18px;
}

.footer-areas {
    columns: 2;
    column-gap: 20px;
}

@media (max-width: 767px) {
    .footer-column {
        margin-bottom: 40px;
    }
    
    .footer-areas {
        columns: 1;
    }
}

@media (max-width: 1200px) {
    .modern-cta {
        margin: 40px 20px !important;
    }
}

@media (max-width: 768px) {
    .modern-cta {
        margin: 20px 10px !important;
    }
}

@media (max-width: 480px) {
    .modern-cta {
        margin: 10px 5px !important;
    }
}

/* Estimator styles */
.estimator-container {
    font-family: Arial, sans-serif;
    color: #444;
}

.progress-container {
    margin-bottom: 10px;
}

.progress-bar {
    background-color: #ff871e !important;
}

.form-label {
    font-weight: 600;
    color: #074a5a;
    margin-bottom: 8px;
}

.form-control.rounded-pill {
    border-radius: 50px;
    padding: 10px 20px;
    border-color: #ddd;
}

.form-control.rounded-pill:focus {
    border-color: #ff871e;
    box-shadow: 0 0 0 0.2rem rgba(255, 135, 30, 0.25);
}

.estimator-title {
    color: #074a5a;
    font-family: 'Lemonmilk', Arial, sans-serif;
    text-align: center;
    margin-bottom: 20px;
}